<template>
    <workspace-wrapper>
        <template #workspace>
            <goal-workspace :layout="stateData.currentSpinResult.layout"
                             :fieldSize="stateData.currentSpinResult.fieldSize"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import GoalWorkspace from "./GoalWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        layout: [],
                        fieldSize: "medium",
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        GoalWorkspace
    }
}
</script>

<style scoped>

</style>
