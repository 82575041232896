<template>
    <div>
        <table>
            <tr v-for="(row, rowIndex) in transposedLayout" :key="rowIndex">
                <td v-for="(symbol, colIndex) in row" :key="colIndex">
                    <div :style='{ backgroundImage: `url( ${getImage(symbol)} )` }'
                        :class="['layout__symbol', `${fieldSize}`]" />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: Array,
            required: true,
        },
        fieldSize: {
            type: String,
            required: true
        },
    },
    computed: {
        transposedLayout() {
            return this.layout[0].map((_, colIndex) => this.layout.map(row => row[colIndex]))
        },
        boomSymbolPath() {
            return require(`@/assets/games/mini/goal/boom.png`);
        },
        selectedSymbolPath() {
            return require(`@/assets/games/mini/goal/boots.png`);
        },
        bombSymbolPath() {
            return require(`@/assets/games/mini/goal/bomb.png`);
        },
        coverSymbolPath() {
            return require(`@/assets/games/mini/goal/cover.png`);
        },
    },
    methods: {
        rowCount() {
            return this.layout[0].length;
        },
        colCount() {
            return this.layout.length;
        },
        symbolSize() {
            if (this.fieldSize === "small") {
                return 94;
            } else if (this.fieldSize === "medium") {
                return 69;
            } else {
                return 59;
            }
        },
        getImage(symbol) {
            if (symbol === 'O') {
                return this.boomSymbolPath
            } else if (symbol === 'B') {
                return this.bombSymbolPath;
            } else if (symbol === 'G') {
                return this.selectedSymbolPath;
            } else {
                return this.coverSymbolPath;
            }
        }
    },
    mounted() {
        const symbolSize = this.symbolSize();
        const rows = this.rowCount();
        const height = symbolSize * rows;
        this.$store.dispatch('workspace/setHeight', height);
        this.$store.dispatch('workspace/setWidth', this.symbolSize() * this.colCount());
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.small {
    width: 94px;
    height: 94px;
}

.medium {
    width: 69px;
    height: 69px;
}

.large {
    width: 59px;
    height: 59px;
}
</style>
